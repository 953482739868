import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SimulationParameters } from '../models/simulation-parameters';
import { AppLoggerService } from '../logging/app-logger.service';
import { Subscriber } from 'flux-for-frameworks-ts';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { Simulation } from '../models/simulation';
import { SimulationService } from '../services/simulation.service';

@Component({
  selector: 'app-simulation-parameters',
  templateUrl: './simulation-parameters.component.html',
  styleUrls: ['./simulation-parameters.component.less']
})
export class SimulationParametersComponent implements OnInit, OnDestroy {
  parameters: SimulationParameters;
  subscriber: Subscriber;
  simulation: Simulation;
  discountedTotalEstimate: number;
  averageYearlyRevenue: number;
  @Input() id: string;

  constructor(private simulationStorage: SimulationStorageService, private logger: AppLoggerService,
              private simulationService: SimulationService) { }

  ngOnInit() {
    this.subscriber = new Subscriber('simulation-parameters', () => {
      this.logger.logInfo([this.logger.createCalculatorPrefix(this.id), 'Simulations updated, updating view.'].join(' '),
        SimulationParametersComponent.name);
      this.refresh();
    });
    this.simulationStorage.addSubscriber(this.subscriber);

    this.refresh();
  }

  ngOnDestroy() {
    this.simulationStorage.removeSubscriber(this.subscriber.name);
  }

  refresh() {
    this.parameters = this.simulationStorage.getSimulationParameters(this.id);
    this.simulation = this.simulationStorage.getSimulation(this.id);

    const discountedCashFlows = this.simulation.discountedTotalCashFlows ? this.simulation.discountedTotalCashFlows : [];
    const discountedCumulative = this.simulationService.calculateCumulativeYearlyCashFlow(
      [], Object.keys(discountedCashFlows), discountedCashFlows, 0);

    this.discountedTotalEstimate = this.roundNumberToNearestHundred(discountedCumulative[discountedCumulative.length - 1]);

    const years = Object.keys(this.simulation.totalCashFlows);
    this.averageYearlyRevenue = years.reduce((prev, cur, index) => prev + this.simulation.totalCashFlows[cur], 0) / years.length;
  }

  roundNumberToNearestHundred(n: number) {
    return Math.round(n / 100) * 100;
  }
}
