import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimulationService } from '../services/simulation.service';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { AppLoggerService } from '../logging/app-logger.service';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.less']
})
export class FrontPageComponent implements OnInit {

  constructor(private router: Router, private simulationStorageService: SimulationStorageService, private logger: AppLoggerService) { }

  ngOnInit() {
  }

  gotoDemo() {
    this.router.navigate(['/simulaatio/demo']);
  }

  generateNewSimulation() {
    const id = this.simulationStorageService.createNewSimulation();
    this.router.navigate(['/simulaatio/' + id]);
  }

  importSimulation() {
    this.logger.logInfo('User action -> import simulation',
      FrontPageComponent.name);
  }
}
