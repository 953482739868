import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { AppLoggerService } from '../logging/app-logger.service';
import { Simulation } from '../models/simulation';

@Component({
  selector: 'app-export-simulation',
  templateUrl: './export-simulation.component.html',
  styleUrls: ['./export-simulation.component.less']
})
export class ExportSimulationComponent implements OnChanges {
  json: string;
  @Input() id: string;

  constructor(private simulationStorageService: SimulationStorageService,
    private logger: AppLoggerService) { }

  ngOnChanges() {
    this.logger.logInfo('App -> loading simulation: ' + this.id,
      ExportSimulationComponent.name);

    const simu = this.simulationStorageService.getSimulation(this.id);
    if (simu !== undefined) {
      const downloadable = new Simulation(simu.id, simu.forestCompartments, {}, {}, {}, simu.simulationParameters);
      this.json = JSON.stringify(downloadable);
    } else {
      this.json = '';
    }
  }

}
