import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFi from '@angular/common/locales/en-FI';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddCompartmentFormComponent } from './add-compartment-form/add-compartment-form.component';
import { GlobalAppErrorHandler } from './config/errorhandler';
import { GlobalHttpInterceptorService } from './config/globalhttpinterceptor.service';
import { NavigationComponent } from './navigation/navigation.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CompartmentListComponent } from './compartment-list/compartment-list.component';
import { SimulationParametersFormComponent } from './simulation-parameters-form/simulation-parameters-form.component';
import { ChartComponent } from './chart/chart.component';
import { SimulationParametersComponent } from './simulation-parameters/simulation-parameters.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { SimulationListComponent } from './simulation-list/simulation-list.component';
import { InfoComponent } from './info/info.component';
import { TosComponent } from './tos/tos.component';
import { FooterComponent } from './footer/footer.component';
import { ExportSimulationComponent } from './export-simulation/export-simulation.component';
import { ImportSimulationComponent } from './import-simulation/import-simulation.component';

const appRoutes: Routes = [
  { path: '', component: FrontPageComponent },
  { path: 'info', component: InfoComponent },
  { path: 'tos', component: TosComponent },
  { path: 'simulaatio/:id', component: CalculatorComponent },
  { path: '**', component: PageNotFoundComponent }
];

// the second parameter 'fr' is optional
registerLocaleData(localeFi, 'fi');

@Injectable()
export class WindowWrapper extends Window {}
export function getWindow() { return window; }

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    AddCompartmentFormComponent,
    NavigationComponent,
    SimulationParametersFormComponent,
    CalculatorComponent,
    CompartmentListComponent,
    SimulationParametersFormComponent,
    ChartComponent,
    SimulationParametersComponent,
    FrontPageComponent,
    SimulationListComponent,
    InfoComponent,
    TosComponent,
    FooterComponent,
    ExportSimulationComponent,
    ImportSimulationComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      countDuplicates: true,
      progressBar: true
    })
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    // {provide: WindowWrapper, useFactory: getWindow},
    {provide: ErrorHandler, useClass: GlobalAppErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
