import { Injectable } from '@angular/core';
import { LogEntry, InfoLevel, ErrorLevel, WarningLevel, DebugLevel } from './log-entry';
import { Notifier } from 'flux-for-frameworks-ts';
import { LoggerStorageService } from './logger-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoggerService {
  constructor(private storage: LoggerStorageService) {
  }

  logInfo(message: string, calling?: string) {
    this.log(InfoLevel, message, calling);
  }

  logError(message: string, calling?: string) {
    this.log(ErrorLevel, message, calling);
  }

  logWarning(message: string, calling?: string) {
    this.log(WarningLevel, message, calling);
  }

  logDebug(message: string, calling?: string) {
    this.log(DebugLevel, message, calling);
  }

  createCalculatorPrefix(id: string) {
    return '[Calculator: ' + id + ']';
  }

  private log(logLevel: string, message: string, calling?: string) {
    const entry = new LogEntry(logLevel, message, calling);
    this.storage.persistLogMessage(entry);
  }
}
