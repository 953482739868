import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { Subscriber } from 'flux-for-frameworks-ts';
import { SimulatedCompartment } from '../models/simulated-compartment';
import { Simulation } from '../models/simulation';
import { AppLoggerService } from '../logging/app-logger.service';
import { SimulationService } from '../services/simulation.service';
import { NotificationService } from '../services/notification.service';
import { ForestCompartment } from '../models/forest-compartment';

@Component({
  selector: 'app-compartment-list',
  templateUrl: './compartment-list.component.html',
  styleUrls: ['./compartment-list.component.less']
})
export class CompartmentListComponent implements OnInit, OnDestroy {
  simulation: Simulation;
  compartments: ForestCompartment[] = [];
  firstYearCompartments: SimulatedCompartment[] = [];
  finalCompartments: SimulatedCompartment[] = [];
  currentCashFlow: number;
  subscriber: Subscriber;
  selectedCompartmentFormId?: number;
  totalArea: number;
  @Input() id: string;

  constructor(private simulationStorage: SimulationStorageService,
              private simulationService: SimulationService,
              private logger: AppLoggerService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.refresh();

    if (this.simulation instanceof Object) {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id),
        'Found stored forest compartment simulation, length: ' + this.compartments.length].join(' '),
        CompartmentListComponent.name);
    }

    this.subscriber = new Subscriber('compartment-list', () => {
      this.logger.logInfo([this.logger.createCalculatorPrefix(this.id), 'Simulation updated, updating compartment list.'].join(' '),
        CompartmentListComponent.name);

      this.refresh();
    });
    this.simulationStorage.addSubscriber(this.subscriber);
  }

  ngOnDestroy() {
    this.simulationStorage.removeSubscriber(this.subscriber.name);
  }

  private refresh() {
    this.simulation = this.simulationStorage.getSimulation(this.id);
    this.compartments = this.simulation.forestCompartments;
    this.firstYearCompartments = this.simulationService.getCompartmentByYear(this.simulation, 0);
    this.finalCompartments = this.simulationService.getCompartmentByYear(this.simulation, this.simulation.totalYears - 1);
    this.currentCashFlow = this.simulation.totalCashFlows[0] ? this.simulation.totalCashFlows[0] : 0;
    this.totalArea = this.compartments.map(fc => fc.area).reduce((prev, cur) => prev + cur, 0);
  }

  editCompartment(id: number) {
    this.logger.logInfo([this.logger.createCalculatorPrefix(this.id), 'User action: edit compartment with id', id].join(' '),
        CompartmentListComponent.name);

    this.selectedCompartmentFormId = id;
  }

  removeCompartment(id: number) {
    this.logger.logInfo([this.logger.createCalculatorPrefix(this.id), 'User action: remove compartment with id', id].join(' '),
        CompartmentListComponent.name);
    if (this.simulationStorage.removeCompartment(this.id, id)) {
      this.notificationService.success('Kuvio poistettu');
    } else {
      this.notificationService.error('Kuvion poisto epäonnistui :(');
    }
  }
}
