export class LogEntry {
  readonly logLevel: string;
  readonly message: string;
  readonly callingClass: string;

  constructor(logLevel: string, message: string, calling?: string) {
    this.logLevel = logLevel;
    this.message = message;
    this.callingClass = calling;
  }
}

export const InfoLevel = 'info';
export const ErrorLevel = 'error';
export const WarningLevel = 'warning';
export const DebugLevel = 'debug';
