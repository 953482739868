import { Injectable } from '@angular/core';
import { ForestCompartment } from '../models/forest-compartment';
import { FirstHarvestId, NormalHarvestId } from '../models/harvest-type';
import { Region, EtelaRegion, KeskiRegion, PohjoisRegion } from '../models/region';

@Injectable({
  providedIn: 'root'
})
export class WoodGrowthEstimatorService {

  constructor() { }

  estimateCompartmentGrowth(fc: ForestCompartment, region: Region) {
    const singleTreeHarvested = fc.harvests.length > 0;
    // if we have a previous single-tree harvest, we can use higher estimates for growth (source: Pukkala, et. al., 2011)
    const continuousCoverageAdjustment = (singleTreeHarvested) ? 1.03 : 1.0;

    // adjust for region
    const regionAdjustment = this.calculateRegionAdjustment(region);

    // growth model based on own forest parcel data
    const growthModel = (0.0002322242 * Math.pow(fc.age, 3)) + (-0.032 * Math.pow(fc.age, 2)) + (1.131 * Math.pow(fc.age, 1));

    return regionAdjustment * continuousCoverageAdjustment * growthModel;
  }

  calculateRegionAdjustment(region: Region) {
    switch (region.id) {
      case EtelaRegion.id:
        return 1.2;
      case KeskiRegion.id:
        return 1.0;
      case PohjoisRegion.id:
        return 0.8;
    }
  }
}
