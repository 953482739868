import { Injectable } from '@angular/core';
import ls, {get, set, remove} from 'local-storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  // uses https://www.npmjs.com/package/local-storage for abstraction
  constructor() { }

  set(key: string, value: any) {
    return set(key, value);
  }

  get(key: string, defaultValue: any) {
    return get(key) || defaultValue;
  }

  delete(key: string) {
    return remove(key);
  }

  setObject(key: string, value: object) {
    return this.set(key, value);
  }

  getObject(key: string) {
    return this.get(key, '{}');
  }

  exists(key: string) {
    return this.get(key, null) !== null ? true : false;
  }
}
