import { HarvestType } from './harvest-type';
import { CashFlow } from './cashflow';

export class Harvest {
  harvestType: HarvestType;
  compartmentAge: number;
  volumeChangePerHectare: number;
  cashflow: CashFlow;
  logHarvestVolumePerHectare: number;
  fiberHarvestVolumePerHectare: number;
  logHarvestVolumePercentage: number;
  fiberHarvestVolumePercentage: number;

  constructor(harvestType: HarvestType, compartmentAge: number, volumeChangePerHectare: number, logHarvestVolumePerHectare: number,
              fiberHarvestVolumePerHectare: number, cashFlow: CashFlow) {
    this.harvestType = harvestType;
    this.compartmentAge = compartmentAge;
    this.volumeChangePerHectare = volumeChangePerHectare;
    this.logHarvestVolumePerHectare = logHarvestVolumePerHectare;
    this.fiberHarvestVolumePerHectare = fiberHarvestVolumePerHectare;
    this.cashflow = cashFlow;

    this.logHarvestVolumePercentage = logHarvestVolumePerHectare / volumeChangePerHectare;
    this.fiberHarvestVolumePercentage = fiberHarvestVolumePerHectare / volumeChangePerHectare;
  }
}
