import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';

// https://www.tektutorialshub.com/angular/angular-http-error-handling/
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private notificationService: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error(`${error.message}`);
          } else {
            console.error(`${error.message}`);
            switch (error.status) {
              case 401:      // login
                this.router.navigateByUrl('/login');

                break;
              case 403:     // forbidden
                this.router.navigateByUrl('/');
                this.notificationService.error('Unauthorized', error.message);

                break;
              default:
                this.notificationService.error('Error occurred', error.message);
            }
          }
        } else {
          console.error(error);
        }
        return throwError(error);
      })
    );
  }
}
