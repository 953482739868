import { SimulationParameters } from './simulation-parameters';

export class CashFlow {
  readonly totalForLogs: number;
  readonly totalForPulpwood: number;
  readonly total: number;

  constructor(logHarvestVolume: number, fiberHarvestVolume: number, simulationParameters: SimulationParameters) {
    this.totalForLogs = logHarvestVolume * simulationParameters.mainLogPrice;
    this.totalForPulpwood = fiberHarvestVolume * simulationParameters.mainPulpwoodPrice;
    this.total = this.totalForLogs + this.totalForPulpwood;
  }
}
