import { Component, OnInit, Input } from '@angular/core';
import { Option } from 'tsoption';
import { SimulationParameters, simulationParameterRegions } from '../models/simulation-parameters';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { KeskiRegion, Regions, Region } from '../models/region';
import { AppLoggerService } from '../logging/app-logger.service';

@Component({
  selector: 'app-simulation-parameters-form',
  templateUrl: './simulation-parameters-form.component.html',
  styleUrls: ['./simulation-parameters-form.component.less']
})
export class SimulationParametersFormComponent implements OnInit {
  simulationParameters: SimulationParameters;
  selectedRegion: string;
  regions: Region[];
  @Input() id: string;

  constructor(private simulationStorageService: SimulationStorageService,
              private logger: AppLoggerService) {}

  ngOnInit() {
    this.regions = Regions;
    const stored = this.simulationStorageService.getSimulationParameters(this.id);

    if (stored instanceof Object) {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id),
        'Found stored simulation parameters', ':', JSON.stringify(stored).replace(/\\/g, '')].join(' '),
        SimulationParametersFormComponent.name);
      this.simulationParameters = stored;
    } else {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id),
        'Did not find stored parameters -> setting default simulation parameters'].join(' '),
        SimulationParametersFormComponent.name);
      this.setDefaultSimulationParameters();
    }
  }

  resetForm() {
    this.setDefaultSimulationParameters();
    this.logger.logInfo([
      this.logger.createCalculatorPrefix(this.id),
      'User action -> reset simulation parameters to default:' +
      JSON.stringify(this.simulationParameters)].join(' '), SimulationParametersFormComponent.name);

    this.simulationStorageService.saveSimulationParameters(this.id, this.simulationParameters);
  }

  private setDefaultSimulationParameters() {
    this.selectedRegion = KeskiRegion.id;
    this.simulationParameters = simulationParameterRegions.get(KeskiRegion.id);
  }

  updatecashflowEstimate() {
    this.logger.logInfo([this.logger.createCalculatorPrefix(this.id),
      'User action -> setting new simulation parameters: ' +
      JSON.stringify(this.simulationParameters)].join(' '), SimulationParametersFormComponent.name);

    this.simulationStorageService.saveSimulationParameters(this.id, this.simulationParameters);
  }
}
