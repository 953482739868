import { GeneralValueObject } from './general-value-object';

export const FirstHarvestId = 'ensiharvennus';
export const NormalHarvestId = 'harvennus';

export class HarvestType extends GeneralValueObject {}

export const FirstHarvest = new HarvestType(FirstHarvestId, 'Ensiharvennus');
export const NormalHarvest = new HarvestType(NormalHarvestId, 'Harvennus');

export const HarvestTypes = [
  FirstHarvest,
  NormalHarvest
];
