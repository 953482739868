import { Injectable } from '@angular/core';
import { Option } from 'tsoption';
import { v4 as uuidv4 } from 'uuid';

import { SimulatedCompartment } from '../models/simulated-compartment';
import { SimulationParameters } from '../models/simulation-parameters';
import { HarvestEstimatorService } from './harvest-estimator.service';
import { ForestCompartment } from '../models/forest-compartment';
import { Simulation } from '../models/simulation';
import { ForestCompartmentTypes } from '../models/forest-compartment-type';
import { SimulationParametersService } from './simulation-parameters.service';
import { WoodGrowthEstimatorService } from './wood-growth-estimator.service';
import { Regions } from '../models/region';
import { LaskuriConfiguration } from '../configuration/laskuri-configuration';
import { HarvestType, FirstHarvest, NormalHarvest } from '../models/harvest-type';
import { Harvest } from '../models/harvest';
import { CashFlow } from '../models/cashflow';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor(private harvestEstimatorService: HarvestEstimatorService,
              private simulationParametersService: SimulationParametersService,
              private woodGrowthEstimatorService: WoodGrowthEstimatorService) { }

  // 1. We could store simulation as just {compartments: SimulatedCompartment[]}, where compartments would be as they were originally added.
  // 2. Cash flows would be RECALCULATED each time user opens up the app.
  // OR
  // 1. We could store simulation with all its' cash flow history??? How long history would that be, hundred years? For future use,
  // I think cash flows would need to be at least cached, so might as well prepare for it in front end code. So, simulation would
  // be {compartments: SimulatedCompartment[], cashFlows: CashFlow[]}, where cash flows would be ADDED/DELETED PER COMPARTMENT each time
  // user adds/deletes a compartment.
  // 2. But we would benefit more from having the cash flows as PER YEAR, so that compartment list could show just the year 0 cash flows,
  // and graph could show sum_ of_cash_flows(year). We'd then need to RECALCULATE all cash flows when compartments change.
  // Note, that this might potentially create a HUGE Json object in local storage, is that a problem?

  // We want to show to user 1) original compartments, 2) calculation about cashflow
  // if harvesting would be done NOW -> This means we need to calculate
  // wood growth FIRST, THEN harvesting results SECOND, them store them,
  // EXCEPT FOR THE FIRST YEAR, when we don't calculate wood growth.
  simulate(simulation: Simulation, year?: number): Simulation {
    const simulationParameters = simulation.simulationParameters;

    const currentYear = year === undefined ? 0 : year;
    if (year === undefined) {
      const resetSimulation = this.resetSimulation(simulation);
      // no wood growth or aging on initial year
      return this.simulate(
        this.harvest(resetSimulation, simulationParameters, currentYear), currentYear + 1);
    } else if (currentYear < 60) {
      const growthSimulated = this.growWood(simulation, simulationParameters, currentYear);
      const harvested = this.harvest(growthSimulated, simulationParameters, currentYear);
      const aged = this.growOld(harvested);
      return this.simulate(
        aged, currentYear + 1);
    } else {
      return simulation;
    }
  }

  growOld(simulation: Simulation) {
    const latestCompartmentYear = this.getLatestSimulationYear(simulation);
    const grown = simulation.simulatedCompartmentsPerYear[latestCompartmentYear].map((s: SimulatedCompartment) => {
      const fc = s.compartment;
      const updated = new ForestCompartment(fc.id, fc.age + 1,
        fc.forestCompartmentType, fc.volume, fc.area, fc.harvests);
      return new SimulatedCompartment(updated, s.simulationYear, s.isHarvested, s.cashflow);
    });
    simulation.simulatedCompartmentsPerYear[latestCompartmentYear] = grown;
    return simulation;
  }

  growWood(simulation: Simulation, simulationParameters: SimulationParameters, year: number) {
    const latestCompartmentYear = this.getLatestSimulationYear(simulation);
    const latestYearCompartments = this.getSelectedYearCompartments(latestCompartmentYear, simulation);

    const simulatedCompartments = latestYearCompartments.map(fc => {
      const newVolume = fc.volume + this.calculateCompartmentVolumeGrowth(fc, simulationParameters);
      const updated = new ForestCompartment(fc.id, fc.age,
        fc.forestCompartmentType, newVolume, fc.area, fc.harvests);
      return this.createSimulatedCompartmentWithHarvest(
        updated, simulationParameters, year, null);
    });

    // return simulation with updated compartments
    const newCompartments = simulation.simulatedCompartmentsPerYear;
    newCompartments[year] = simulatedCompartments;

    return this.createSimulation(
      simulation.id, simulation.forestCompartments, newCompartments, simulationParameters);
  }

  createSimulation(id: string,
                   compartments: ForestCompartment[],
                   simulatedCompartmentsPerYear: object,
                   simulationParameters: SimulationParameters) {
    return new Simulation(
      id,
      compartments,
      simulatedCompartmentsPerYear,
      this.createCashFlowsPerYear(simulatedCompartmentsPerYear),
      this.createDiscountedCashFlowsPerYear(simulatedCompartmentsPerYear, simulationParameters.discountRate),
      simulationParameters);
  }

  harvest(simulation: Simulation, simulationParameters: SimulationParameters, year: number) {
    const latestCompartmentYear = this.getLatestSimulationYear(simulation);

    // simulate growth of previous year compartments
    const latestYearCompartments = this.getSelectedYearCompartments(latestCompartmentYear, simulation);
    const simulatedCompartments = latestYearCompartments.map(fc => {
      const harvest = this.isCompartmentHarvestable(fc);
      return this.createSimulatedCompartmentWithHarvest(fc, simulationParameters, year, harvest);
    });
    /*
    this.logger.logDebug('Simulation, year '
      + year + ': previous year is ' + previousCompartmentYear + ', length of compartments is '
      + previousYearCompartments.length, SimulationService.name);*/

    // return simulation with updated compartments
    const newCompartments = simulation.simulatedCompartmentsPerYear;
    newCompartments[year] = simulatedCompartments;

    return this.createSimulation(
      simulation.id, simulation.forestCompartments, newCompartments, simulationParameters);
  }

  private resetSimulation(simulation: Simulation) {
    return this.createSimulation(
      simulation.id,
      simulation.forestCompartments,
      {},
      simulation.simulationParameters);
  }

  getLatestSimulationYear(simulation: Simulation) {
    const alreadySimulatedYears = Object.keys(simulation.simulatedCompartmentsPerYear)
      .map(k => parseInt(k, 10));
    return alreadySimulatedYears.sort((a: number, b: number) => a - b).pop();
  }

  private getSelectedYearCompartments(compartmentYear: number, simulation: Simulation) {
    return (compartmentYear !== undefined) ?
      this.getCompartmentByYear(simulation, compartmentYear).map(sc => sc.compartment) :
      simulation.forestCompartments;
  }

  isCompartmentHarvestable(c: ForestCompartment): HarvestType | null {
    // years since last harvest doesn't matter, we harvest if criteria is fulfilled
    if (c.age >= LaskuriConfiguration.earliestFirstHarvest &&
      c.age <= LaskuriConfiguration.cutOffAgeBetweenFirstAndNormalHarvest &&
      c.volume >= 100 &&
      c.previousHarvestType !== FirstHarvest) { // first harvest criteria
      return FirstHarvest;
    } else if (c.age >= LaskuriConfiguration.cutOffAgeBetweenFirstAndNormalHarvest && c.volume >= 200) {
      return NormalHarvest;
    } else {
      return null;
    }
  }

  generateNewCompartmentId(existingCompartments: ForestCompartment[]) {
    const maxId = existingCompartments.map(c => c.id).sort().pop();
    return Option
      .of(maxId)
      .map(max => max + 1).getOrElse(1);
  }

  generateNewSimulationId() {
    return uuidv4();
  }

  createSimulatedCompartmentWithHarvest(c: ForestCompartment,
                             simulationParameters: SimulationParameters,
                             year: number,
                             harvestType?: HarvestType) {
    if (harvestType !== null) {
      const harvest = this.createHarvest(c, simulationParameters, harvestType);
      const updatedCompartment = new ForestCompartment(
        c.id,
        c.age,
        c.forestCompartmentType,
        c.volume - harvest.volumeChangePerHectare,
        c.area,
        c.harvests.concat([harvest])
      );
      const cashflow = new CashFlow(harvest.logHarvestVolumePerHectare * updatedCompartment.area,
        harvest.fiberHarvestVolumePerHectare * updatedCompartment.area, simulationParameters);

      return new SimulatedCompartment(updatedCompartment, year, true, cashflow);
    } else {
      return new SimulatedCompartment(c, year, false);
    }
  }

  createHarvest(c: ForestCompartment, simulationParameters: SimulationParameters, harvestType: HarvestType) {
    const harvestPercentage = this.harvestEstimatorService.calculateHarvestPercentage(c.age);
    const logHP = this.harvestEstimatorService.calculateHarvestLogPercentage(c.age);

    const harvestVolume = c.volume * harvestPercentage;
    const logHarvestVolume = harvestVolume * c.area * logHP;
    const fiberHarvestVolume = harvestVolume * c.area * (1 - logHP);

    const volumeAfter = c.volume
      - (logHarvestVolume / c.area)
      - (fiberHarvestVolume / c.area);
    const volumeChange = c.volume - volumeAfter;

    const cashflow = new CashFlow(logHarvestVolume, fiberHarvestVolume, simulationParameters);

    const harvest = new Harvest(harvestType, c.age, volumeChange,
      harvestVolume * logHP, harvestVolume * (1 - logHP), cashflow);

    return harvest;
  }

  calculateCompartmentVolumeGrowth(fc: ForestCompartment, parameters: SimulationParameters) {
    const region = Regions.find(r => r.id === parameters.region);
    return this.woodGrowthEstimatorService.estimateCompartmentGrowth(fc, region);
  }

  createCashFlowsPerYear(simulatedCompartmentsPerYear: object) {
    const cfs = {};
    Object.keys(simulatedCompartmentsPerYear).forEach(year => {
      const ss: SimulatedCompartment[] = simulatedCompartmentsPerYear[year];
      const sum = ss
        .map((s: SimulatedCompartment) => s.cashflow !== undefined ? s.cashflow.total : 0)
        .reduce((acc, cur) => acc + cur, 0);
      cfs[year] = sum;
    });

    return cfs;
  }

  createDiscountedCashFlowsPerYear(simulatedCompartmentsPerYear: object,
                                   discountPercentage: number) {
    const cfs = {};
    Object.keys(simulatedCompartmentsPerYear).forEach(year => {
      const numericYear = parseInt(year, 10);
      const ss: SimulatedCompartment[] = simulatedCompartmentsPerYear[numericYear];
      const discount = Math.pow(1 - (discountPercentage / 100), numericYear);
      const sum = ss
        .map((s: SimulatedCompartment) => s.cashflow !== undefined ? s.cashflow.total : 0)
        .reduce((acc, cur) => acc + cur, 0) * discount;

      cfs[year] = sum;
    });

    return cfs;
  }

  getCompartmentByYear(simulation: Simulation, year: number): SimulatedCompartment[] {
    return simulation.simulatedCompartmentsPerYear[year] ? simulation.simulatedCompartmentsPerYear[year] : [];
  }

  getTotalCashFlowsByYear(simulation: Simulation, year: number): number {
    return simulation.totalCashFlows[year];
  }

  getDiscountedTotalCashFlows(simulation: Simulation, year: number): number {
    return simulation.discountedTotalCashFlows[year];
  }

  createDefaultCompartment(): ForestCompartment {
    return new ForestCompartment(this.generateNewCompartmentId([]), 40, ForestCompartmentTypes[1].id, 200, 10.0, []);
  }

  createDemoSimulation() {
    const first = this.createDefaultCompartment();
    return this.simulate(this.createSimulation('demo', [
      this.createDefaultCompartment(),
      new ForestCompartment(this.generateNewCompartmentId([first]), 20, ForestCompartmentTypes[1].id, 100, 10.0, [])
    ], {}, this.simulationParametersService.createDemoParameters()));
  }

  calculateCumulativeYearlyCashFlow(sum: number[], years: string[], cashflows: {}, total: number) {
    if (years.length > 0) {
      const newTotal = total + cashflows[years.shift()];
      sum.push(newTotal);
      return this.calculateCumulativeYearlyCashFlow(sum, years, cashflows, newTotal);
    } else {
      return sum;
    }
  }
}
