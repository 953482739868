import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly toastrSettings: object;

  constructor(private toastr: ToastrService) {
    this.toastrSettings = {};
  }

  success(title: string, description?: string) {
    this.toastr.success(description, title, this.toastrSettings);
  }

  warning(title: string, description?: string) {
    this.toastr.warning(description, title, this.toastrSettings);
  }

  error(title: string, description?: string) {
    this.toastr.error(description, title, this.toastrSettings);
  }

  info(title: string, description?: string) {
    this.toastr.info(description, title, this.toastrSettings);
  }
}
