import { Component, OnInit, OnDestroy } from '@angular/core';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { Simulation } from '../models/simulation';
import { Subscriber } from 'flux-for-frameworks-ts';
import { AppLoggerService } from '../logging/app-logger.service';

@Component({
  selector: 'app-simulation-list',
  templateUrl: './simulation-list.component.html',
  styleUrls: ['./simulation-list.component.less']
})
export class SimulationListComponent implements OnInit, OnDestroy {
  simulations: Simulation[] = [];
  selectedSimulationId: string;
  private subscriber: Subscriber;

  constructor(private simulationStorageService: SimulationStorageService,
              private logger: AppLoggerService) { }

  ngOnInit() {
    this.subscriber = new Subscriber('simulation-list', () => {
      this.logger.logInfo('Simulations updated, updating simulation list.',
      SimulationListComponent.name);

      this.refresh();
    });
    this.simulationStorageService.addSubscriber(this.subscriber);

    this.refresh();
  }


  ngOnDestroy() {
    this.simulationStorageService.removeSubscriber(this.subscriber.name);
  }

  private refresh() {
    this.simulations = [];
    const ss = this.simulationStorageService.getSimulations();
    Object.values(ss).forEach(simulation => {
      this.simulations.push(simulation);
    });
  }

  removeSimulation(id: string) {
    this.logger.logInfo('User action -> removing simulation: ' + id,
      SimulationListComponent.name);

    this.simulationStorageService.removeSimulation(id);
  }

  exportSimulation(id: string) {
    this.logger.logInfo('User action -> export simulation: ' + id,
      SimulationListComponent.name);

    this.selectedSimulationId = id;
  }
}
