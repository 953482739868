import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as c3 from 'c3';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { Simulation } from '../models/simulation';
import { AppLoggerService } from '../logging/app-logger.service';
import { Subscriber } from 'flux-for-frameworks-ts';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { SimulationService } from '../services/simulation.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.less']
})
export class ChartComponent implements OnInit, OnDestroy {
  private simulation: Simulation;
  private dates: Date[];
  private chart: c3.ChartAPI;
  private hideChart: boolean;
  private subscriber: Subscriber;
  @Input() id: string;

  constructor(private simulationStorage: SimulationStorageService,
              private simulationService: SimulationService,
              private logger: AppLoggerService) { }

  ngOnInit() {
    this.chart = c3.generate({
      bindto: '#chart',
      data: {
        x: 'x',
//        xFormat: '%Y%m%d', // 'xFormat' can be used as custom format of 'x'
        columns: [
        ],
        type: 'bar',
        types: {
          'kumulatiivinen diskontattu [EUR]': 'line'
        }
      },
      point: {
          show: false
      },
      axis: {
        x: {
            type: 'timeseries',
            tick: {
                format: '%Y'
            }
        }
      },
      grid: {
          x: {
              show: true
          },
          y: {
              show: true
          }
      }
    });

    this.refresh();
    if (this.simulation instanceof Object) {
      this.logger.logInfo(
        [this.logger.createCalculatorPrefix(this.id), 'Found stored simulation cash flow data, length: ' + this.dates.length].join(' '),
        ChartComponent.name);
    }

    this.subscriber = new Subscriber('chart', () => {
      this.logger.logInfo([this.logger.createCalculatorPrefix(this.id), 'Simulation updated, updating cash flow chart.'].join(' '),
        ChartComponent.name);
      this.refresh();
    });
    this.simulationStorage.addSubscriber(this.subscriber);

  }

  ngOnDestroy() {
    this.simulationStorage.removeSubscriber(this.subscriber.name);
  }

  private refresh() {
    this.simulation = this.simulationStorage.getSimulation(this.id);
    const cashFlows = this.simulation.totalCashFlows ? this.simulation.totalCashFlows : [];
    this.dates = Object.keys(cashFlows)
      .map(k => {
        const year = new Date();
        year.setFullYear(year.getFullYear() + parseInt(k, 10));
        return year;
      });
    const nominal = Object.keys(cashFlows)
      .map(k => {
        // return formatCurrency(cashFlows[k], 'fi', '€', 'EUR');
        return (cashFlows[k] as number).toFixed(0);
      });
    const discountedCashFlows = this.simulation.discountedTotalCashFlows ? this.simulation.discountedTotalCashFlows : [];
    const discounted = Object.keys(discountedCashFlows)
      .map(k => {
        return (discountedCashFlows[k] as number).toFixed(0);
      });

    const discountedCumulative = this.simulationService.calculateCumulativeYearlyCashFlow(
      [], Object.keys(discountedCashFlows), discountedCashFlows, 0).map(d => (d as number).toFixed(0));

    this.hideChart = nominal.length === 0;

    this.chart.load({
      columns: [
          ['x', ...this.dates],
          ['nimellinen tulo [EUR]', ...nominal],
          ['diskontattu tulo [EUR]', ...discounted],
          ['kumulatiivinen diskontattu [EUR]', ...discountedCumulative]
      ]
    });
  }
}
