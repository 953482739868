import { Injectable } from '@angular/core';
import { KeskiRegion } from '../models/region';
import { simulationParameterRegions } from '../models/simulation-parameters';

@Injectable({
  providedIn: 'root'
})
export class SimulationParametersService {

  constructor() { }

  createDemoParameters() {
    return simulationParameterRegions.get(KeskiRegion.id);
  }
}
