import { KeskiRegion, EtelaRegion, PohjoisRegion } from './region';

export class SimulationParameters {
  mainLogPrice: number;
  mainPulpwoodPrice: number;
  discountRate: number;
  region: string;

  constructor(mainLogPrice: number,
              mainPulpwoodPrice: number,
              woodGrowth: number, // @todo remove unused parameter
              discountRate: number,
              region: string) {
    this.mainLogPrice = mainLogPrice;
    this.mainPulpwoodPrice = mainPulpwoodPrice;
    this.discountRate = discountRate;
    this.region = region;
  }
}

// https://stackoverflow.com/questions/30019542/es6-map-in-typescript
export let simulationParameterRegions = new Map<string, SimulationParameters>();
simulationParameterRegions.set(EtelaRegion.id, new SimulationParameters(50.0, 18.0, 6.0, 4.0, EtelaRegion.id));
simulationParameterRegions.set(KeskiRegion.id, new SimulationParameters(45.0, 15.0, 5.0, 4.0, KeskiRegion.id));
simulationParameterRegions.set(PohjoisRegion.id, new SimulationParameters(40.0, 13.0, 4.0, 4.0, PohjoisRegion.id));

