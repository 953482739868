import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { AppLoggerService } from '../logging/app-logger.service';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { SimulationService } from '../services/simulation.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.less']
})
export class CalculatorComponent implements OnInit {
  id: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private logger: AppLoggerService,
              private simulationService: SimulationService,
              private simulationStorageService: SimulationStorageService) { }

  ngOnInit() {
    // ...or, if id does not change (router does not reuse this component) "this.route.snapshot.paramMap.get('id');""
    const id = this.route.snapshot.paramMap.get('id');
    this.id = id;

    if (id === 'demo' && this.simulationStorageService.getSimulation('demo') === undefined) {
      this.createDefaultDemoSimulation();
    } else if (this.simulationStorageService.getSimulation(id) === undefined) {
      this.router.navigate(['/page-not-found']);
    } else {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id), 'Loading forest holding simulation'].join(' '), CalculatorComponent.name);
    }
  }

  private createDefaultDemoSimulation() {
    this.logger.logInfo([
      this.logger.createCalculatorPrefix(this.id), 'Creating initial demo simulation'].join(' '), CalculatorComponent.name);

    const demo = this.simulationService.createDemoSimulation();
    this.simulationStorageService.saveSimulation(demo);
  }
}
