import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { ForestCompartment } from '../models/forest-compartment';
import { ForestCompartmentType, ForestCompartmentTypes, Pine } from '../models/forest-compartment-type';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { SimulationService } from '../services/simulation.service';
import { AppLoggerService } from '../logging/app-logger.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-simulation-form',
  templateUrl: './add-compartment-form.component.html',
  styleUrls: ['./add-compartment-form.component.less']
})
export class AddCompartmentFormComponent implements OnInit, OnChanges {
  forestCompartment: ForestCompartment;
  simulation: SimulationStorageService;
  compartmentTypes: ForestCompartmentType[];
  selectedCompartmentType: string;
  @Input() id: string;
  @Input() compartmentId: string;

  constructor(private simulationStorage: SimulationStorageService,
              private simulationService: SimulationService,
              private logger: AppLoggerService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.resetForm();
    this.compartmentTypes = ForestCompartmentTypes;
  }

  // reloads whenever component properties change
  ngOnChanges() {
    this.resetForm();
  }

  private resetForm() {
    if (this.compartmentId !== undefined) {
      const simulation = this.simulationStorage.getSimulation(this.id);
      const found = simulation.forestCompartments.find(c => c.id === Number(this.compartmentId));
      if (this.forestCompartment === undefined) {
        this.logger.logError(
          [this.logger.createCalculatorPrefix(this.id), 'Unable to find compartment with id:', this.compartmentId].join(' '),
          AddCompartmentFormComponent.name);
      } else {
        this.forestCompartment = found;
      }
    } else {
      this.forestCompartment = this.simulationService.createDefaultCompartment();
      this.selectedCompartmentType = ForestCompartmentTypes[1].id;
    }
  }

  addCompartment() {
    if (this.compartmentId !== undefined) {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id),
        'User action -> editing compartment:',
        JSON.stringify(this.forestCompartment).replace(/\\/g, '')].join(' '), AddCompartmentFormComponent.name);
      if (this.simulationStorage.editCompartment(this.id, this.forestCompartment)) {
        this.notificationService.success('Kuvio tallennettu');
      } else {
        this.notificationService.error('Kuvion muokkaus epäonnistui :(');
      }

      this.resetForm();
    } else {
      this.logger.logInfo([
        this.logger.createCalculatorPrefix(this.id),
        'User action -> adding new compartment:',
        JSON.stringify(this.forestCompartment).replace(/\\/g, '')].join(' '), AddCompartmentFormComponent.name);
      if (this.simulationStorage.addCompartment(this.id, this.forestCompartment)) {
        this.notificationService.success('Uusi kuvio lisätty!');
      } else {
        this.notificationService.error('Kuvion lisäys epäonnistui :(');
      }

      this.resetForm();
    }

  }
}
