import { ForestCompartment } from './forest-compartment';
import { CashFlow } from './cashflow';

export class SimulatedCompartment {
  readonly compartment: ForestCompartment;
  readonly cashflow: CashFlow;
  readonly isHarvested: boolean;
  readonly simulationYear: number;

  constructor(c: ForestCompartment,
              year: number,
              isHarvested: boolean,
              cashflow?: CashFlow) {
    this.compartment = c;
    this.simulationYear = year;
    this.isHarvested = isHarvested;
    this.cashflow = cashflow;
  }
}
