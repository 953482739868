import { Injectable } from '@angular/core';
import { TreeNode } from 'flux-for-frameworks-ts';

import { ForestCompartment } from '../models/forest-compartment';
import { BackEndClientService } from './back-end-client.service';
import { Simulation } from '../models/simulation';
import { SimulationService } from './simulation.service';
import { SimulationParameters } from '../models/simulation-parameters';
import { AppLoggerService } from '../logging/app-logger.service';
import { SimulationParametersService } from './simulation-parameters.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationStorageService extends TreeNode {
  private simulations: object;

  constructor(private backEndClient: BackEndClientService,
              private logger: AppLoggerService,
              private simulationService: SimulationService,
              private simulationParametersService: SimulationParametersService) {
    super();

    this.refresh();
  }

  refresh() {
    this.simulations = this.backEndClient.loadSimulations();
  }

  getSimulation(id: string): Simulation | undefined {
    return this.simulations[this.backEndClient.formatSimulationStorageKey(id)];
  }

  getSimulations() {
    return this.simulations;
  }

  createNewSimulation() {
    const id = this.simulationService.generateNewSimulationId();
    const s = this.simulationService.createSimulation(id, [], {},
      this.simulationParametersService.createDemoParameters());

    this.handleUpdate(s);

    return id;
  }

  saveSimulation(simulation: Simulation) {
    this.handleUpdate(simulation);
  }

  updateSimulation(simulation: Simulation) {
    this.logger.logInfo('Updating simulation, forcing simulation recalculation.',
        SimulationStorageService.name);
    const updated = this.simulationService.simulate(
      this.simulationService.createSimulation(
        simulation.id, simulation.forestCompartments, {}, simulation.simulationParameters));
    return this.handleUpdate(updated);
  }

  removeSimulation(id: string) {
    this.backEndClient.removeSimulation(id);
    this.refresh();

    this.publish();
  }

  getSimulationParameters(simulationId: string) {
    const simulation = this.simulations[simulationId];

    if (simulation !== undefined) {
      return simulation.simulationParameters;
    } else {
      this.logger.logError('Unable to find simulation with id: ' + simulationId,
        SimulationStorageService.name);
      return null;
    }
  }

  saveSimulationParameters(simulationId: string, simulationParameters: SimulationParameters) {
    const simulation = this.simulations[simulationId];

    if (simulation) {
      this.logger.logInfo('Simulation parameters updated, forcing simulation recalculation.',
        SimulationStorageService.name);
      const updated = this.simulationService.simulate(
        this.simulationService.createSimulation(
          simulation.id, simulation.forestCompartments, {}, simulationParameters));
      return this.handleUpdate(updated);
    } else {
      this.logger.logError('Unable to find simulation with id: ' + simulationId,
        SimulationStorageService.name);

      return false;
    }
  }

  addCompartment(simulationId: string, c: ForestCompartment) {
    const simulation = this.simulations[simulationId];

    const id = this.simulationService.generateNewCompartmentId(simulation.forestCompartments);
    const compartmentWithId = new ForestCompartment(
      id, c.age, c.forestCompartmentType, c.volume, c.area, c.harvests);

    const updated = this.simulationService.simulate(
      this.simulationService.createSimulation(
        simulation.id, simulation.forestCompartments.concat(compartmentWithId), {}, simulation.simulationParameters));

    return this.handleUpdate(updated);
  }

  editCompartment(simulationId: string, c: ForestCompartment) {
    const simulation = this.simulations[simulationId];

    if (c.id === undefined) {
      this.logger.logError('Trying to edit compartment with unknown id!',
        SimulationStorageService.name);

      return false;
    } else {
      const found = simulation.forestCompartments.find(fc => fc.id === c.id);
      if (found === undefined) {
        this.logger.logError('While trying to edit compartment, did not find compartment with id: ' + c.id,
          SimulationStorageService.name);

        return false;
      } else {
        const updatedCompartments = simulation.forestCompartments.filter(fc => fc.id !== c.id).concat(c);
        const updated = this.simulationService.simulate(
          this.simulationService.createSimulation(
            simulation.id, updatedCompartments, {}, simulation.simulationParameters));

        return this.handleUpdate(updated);
      }

    }
  }

  removeCompartment(simulationId: string, id: number) {
    const simulation = this.simulations[simulationId];

    const updated = this.simulationService.simulate(
      this.simulationService.createSimulation(
        simulation.id, simulation.forestCompartments.filter((fc: ForestCompartment) => fc.id !== id), {}, simulation.simulationParameters));

    return this.handleUpdate(updated);
  }

  private handleUpdate(simulation: Simulation) {
    this.simulations[simulation.id] = simulation;

    return this.persistSimulations();
  }

  private persistSimulations() {
    const result = this.backEndClient.saveSimulations(this.simulations);

    this.publish();

    return result;
  }
}
