import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { throwError } from 'rxjs';

// global error handler https://angular.io/api/core/ErrorHandler
export class GlobalAppErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // this.notificationService.error('An error occurred:', error.error.message);
      console.error('An error occurred:', error.error.message);
    } else {
      // this.notificationService.error(`[${error.status}] An error occurred:`, error.error);
      console.error(error);
    }

    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
