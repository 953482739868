import { Injectable } from '@angular/core';
import { TreeNode } from 'flux-for-frameworks-ts';
import { LogEntry } from './log-entry';

@Injectable({
  providedIn: 'root'
})
export class LoggerStorageService extends TreeNode {
  private messages: LogEntry[];

  constructor() {
    super();
    this.messages = [];
  }

  refresh() {}

  persistLogMessage(entry: LogEntry) {
    this.messages.push(entry);

    console.log(entry);
  }
}
