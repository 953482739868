import { ForestCompartment } from './forest-compartment';
import { SimulationParameters } from './simulation-parameters';

// do not put methods into model classes, b/c it prevents effective serialization/unserialization
export class Simulation {
  readonly id: string;
  readonly forestCompartments: ForestCompartment[];
  readonly simulatedCompartmentsPerYear: object;
  readonly totalCashFlows: object;
  readonly discountedTotalCashFlows: object;
  readonly simulationParameters: SimulationParameters;
  readonly totalYears: number;

  constructor(id: string,
              fcs: ForestCompartment[],
              simulatedCompartmentsPerYear: object,
              cashFlowsPerYear: object,
              discountedCashFlowsPerYear: object,
              simulationParameters: SimulationParameters) {
    this.simulationParameters = simulationParameters;
    this.id = id;
    this.forestCompartments = fcs;
    this.simulatedCompartmentsPerYear = simulatedCompartmentsPerYear;
    this.totalCashFlows = cashFlowsPerYear;
    this.discountedTotalCashFlows = discountedCashFlowsPerYear;
    this.totalYears = Object.keys(simulatedCompartmentsPerYear).length;
  }
}
