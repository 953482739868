import { Harvest } from './harvest';
import { Option } from 'tsoption';
import { HarvestType } from './harvest-type';

export class ForestCompartment {
  id: number;
  age: number;
  forestCompartmentType: string;
  volume: number;
  area: number;
  harvests: Harvest[] = [];
  previousHarvestType: HarvestType;
  yearsSinceLastHarvest: number; // IT'S IMPORTANT NOT TO DEFINE THIS AS ?: OR NULLABLE, DOES NOT WORK CORRECTLY OTHERWISE

  constructor(id: number,
              age: number,
              forestCompartmentType: string,
              volume: number,
              area: number,
              harvests: Harvest[],
              yearsSinceLastHarvest?: number) {
    this.id = id;
    this.age = age;
    this.forestCompartmentType = forestCompartmentType;
    this.volume = volume;
    this.area = area;

    const orderedHarvests = harvests.sort((a, b) => {
      if (a.compartmentAge < b.compartmentAge) {
        return -1;
      } else if (a.compartmentAge > b.compartmentAge) {
        return 1;
      } else {
        return 0;
      }
    });
    this.harvests = orderedHarvests;

    this.yearsSinceLastHarvest = Option.of(orderedHarvests[orderedHarvests.length-1])
      .map(h => this.age - h.compartmentAge)
      .getOrElse(yearsSinceLastHarvest);
    this.previousHarvestType = Option.of(orderedHarvests[orderedHarvests.length-1])
      .map(h => h.harvestType)
      .getOrElse(null);
  }
}
