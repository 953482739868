import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { SimulationParameters } from '../models/simulation-parameters';
import { Simulation } from '../models/simulation';
import { AppLoggerService } from '../logging/app-logger.service';

@Injectable({
  providedIn: 'root'
})
export class BackEndClientService {
  constructor(private localStorage: LocalStorageService, private logger: AppLoggerService) { }

  saveSimulation(simulation: Simulation) {
    const simulations = this.localStorage.get('simulations', {});
    simulations[this.formatSimulationStorageKey(simulation.id)] = simulation;

    return this.saveSimulations(simulations);
  }

  loadSimulation(id: string): Simulation {
    const simulations = this.localStorage.get('simulations', {});
    return simulations[this.formatSimulationStorageKey(id)];
  }

  loadSimulations(): Simulation[] {
    return this.localStorage.get('simulations', {});
  }

  removeSimulation(id: string) {
    const simulations = this.localStorage.get('simulations', {});
    delete simulations[this.formatSimulationStorageKey(id)];

    this.saveSimulations(simulations);
  }

  saveSimulations(simulations: object) {
    if (Array.isArray(simulations)) {
      this.logger.logError('Attempting to persist simulations as an array, will not work!',
      BackEndClientService.name);

      return false;
    } else {
      return this.localStorage.set('simulations', simulations);
    }
  }

  formatSimulationStorageKey(id: string) {
    return id;
  }
}
