import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Simulation } from '../models/simulation';
import { AppLoggerService } from '../logging/app-logger.service';
import { SimulationStorageService } from '../services/simulation-storage.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-import-simulation',
  templateUrl: './import-simulation.component.html',
  styleUrls: ['./import-simulation.component.less']
})
export class ImportSimulationComponent implements OnInit {
  jsonText: string;

  constructor(private simulationStorage: SimulationStorageService, private logger: AppLoggerService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm() {
    this.jsonText = null;
  }

  importJSON(jsonText) {
    try {
      const parsed: Simulation = JSON.parse(jsonText);
      this.simulationStorage.updateSimulation(parsed);

      this.notificationService.success('Simulaatio palautettiin onnistuneesti');
    } catch (e) {
      this.logger.logError('App -> import simulation failed. Invalid JSON!',
        ImportSimulationComponent.name);
      this.notificationService.error('Virhe simulaation palautuksessa, epäkelpo syöte');
    }
  }
}
