import { Injectable } from '@angular/core';
import { LaskuriConfiguration } from '../configuration/laskuri-configuration';

@Injectable({
  providedIn: 'root'
})
export class HarvestEstimatorService {

  constructor() { }

  // @todo rakenna logaritminen malli, klog(age)
  calculateHarvestLogPercentage(age: number) {
    const adjustedAge = (age > 90) ? 90 : age;
    // no logs under cut off age
    return (adjustedAge < LaskuriConfiguration.cutOffAgeBetweenFirstAndNormalHarvest)
      ? 0 : -0.006341 * (90 - adjustedAge) + 0.60;
  }

  calculateHarvestPercentage(age: number) {
    const adjustedAge = (age > 90) ? 90 : age;
    const percentage = -0.005228 * (90 - adjustedAge) + 0.6859;

    return percentage;
  }
}
